import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "19a8a31c-3ea4-4668-853b-e2c8e6b4aac5", 
    authority: "https://login.microsoftonline.com/organizations", 
    redirectUri: window.location.origin, 
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);


export const loginRequest = {
  scopes: ["User.Read", "Directory.Read.All"],
};

export const initializeMsal = async () => {
  if (!msalInstance) {
    throw new Error("MSAL Instance is not defined");
  }
  await msalInstance.initialize();
};