import React, { useState, useEffect, useRef } from 'react';
import '../styles/LandingPage.scss';
import { Container, Col, Row, Card, Carousel} from "react-bootstrap";
import ReactPlayer from 'react-player';
import { useHistory, useParams,useLocation } from "react-router-dom";
import { ValidateLogin,UserSession,GetIpAddress } from '../Services/LoginService'
import { isLoggedIn } from '../Services/Auth';
import { history } from '../Services/History';
import { IconArrowForward, IconPhoneFilled, IconMailFilled, IconMapPinFilled, IconHome, IconX } from '@tabler/icons-react';
import { Button, IconButton, AppBar, Toolbar, Tooltip, Typography, Divider, Box, Modal, CardMedia, CardContent, Dialog } from '@mui/material';
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';
import PauseIcon from '@mui/icons-material/Pause';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { useSpring, animated, config } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import LandingPageBanner from './LandingPageBanner';
import Lottie from 'lottie-react';
import animationData from '../assets/HeroBannerAnimation.json';
import { logPageViews } from '../Services/LoginService';
import MetaTags from 'react-meta-tags';
import {browserName,isMobile} from 'react-device-detect';
import * as Bowser from "bowser"; 


const LandingPage = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const search = useLocation().search;
    const params = new URLSearchParams(search);
    const reqStr = params.get("r");
    const crypt = params.get("c");

  useEffect(() => {
    document.title = "MIMS Drugs4dent";
    // console.log(reqStr)
    // console.log(crypt)
    logPage()
    async function logPage() {
      await logPageViews("Landing", "")
    }
    setLoggedIn(isLoggedIn());
  }, []);
  useEffect(() => {
    CheckAthens()
    async function CheckAthens() {

     //url and ip
if(localStorage.getItem("token") === 'undefined' || localStorage.getItem("token") === null)
  {
    localStorage.setItem("documentRefer", document.referrer)
    const tokenResponse = await ValidateLogin('', '', '', await GetIpAddress(), document.referrer,'','','','','');

    if (tokenResponse.success && tokenResponse.data.isSuccess) {
      localStorage.setItem("ip", await GetIpAddress())
      localStorage.setItem('token', tokenResponse.data.token);
      localStorage.setItem('refreshToken', tokenResponse.data.refreshToken);
      localStorage.setItem('sessionId', tokenResponse.data.sessionId);
      localStorage.setItem('fullName', tokenResponse.data.fullName);
      localStorage.setItem('email', tokenResponse.data.fullName);
      localStorage.setItem('phoneNumber', tokenResponse.data.phoneNumber);
      localStorage.setItem('address', tokenResponse.data.address);
      localStorage.setItem('company', tokenResponse.data.company);
      localStorage.setItem('occupation', tokenResponse.data.occupation);
      localStorage.setItem('userId', tokenResponse.data.userId);
      localStorage.setItem('gateway', tokenResponse.data.isImGateway);
    
        localStorage.setItem('subscriptionType', tokenResponse.data.eStoreWebModels[0].subType);
        localStorage.setItem('subscriptionStart', tokenResponse.data.eStoreWebModels[0].subcriptionStartDate);
        localStorage.setItem('subscriptionEnd', tokenResponse.data.eStoreWebModels[0].subcriptionEndDate);
        localStorage.setItem('licenseCount', tokenResponse.data.eStoreWebModels[0].licenseCount);
        var result = Bowser.getParser(window.navigator.userAgent);
        //result.parsedResult.browser.version
      await UserSession('Add',tokenResponse.data.sessionId,'',tokenResponse.data.userId,await GetIpAddress(),document.referrer,'URLAndIP',isMobile == true?'Mobile':'Desktop',browserName,result.parsedResult.browser.version,'')

      //setError({ email: false, password: false });
      //history.replace('/Home'); // Redirect to the home page
      window.location.reload();
    } else {
      console.log('suc')
    
    }
  }
// Close modal then proceed to login
  
// if(reqStr != null)
//   {
//     console.log("jdjfdj")
//   }else{
//     console.log("sdf")
//   }
if (reqStr === null || crypt === null) {

  console.log(window.location.href)
  if(window.location.href.includes("openathens"))
    {
      window.location.href = 'https://openathens.mims.com.au/drugs4dent';
    }
  //window.location.href = 'https://openathens.mims.com.au/drugs4dent';
}else{
  const tokenResponse = await ValidateLogin('', '', '', '', '','',reqStr,crypt);

  console.log(tokenResponse);


  if (tokenResponse.success && tokenResponse.data.isSuccess) {
    localStorage.setItem("ip", await GetIpAddress())
    localStorage.setItem('token', tokenResponse.data.token);
    localStorage.setItem('refreshToken', tokenResponse.data.refreshToken);
    localStorage.setItem('sessionId', tokenResponse.data.sessionId);
    localStorage.setItem('fullName', tokenResponse.data.fullName);
    localStorage.setItem('email', tokenResponse.data.fullName);
    localStorage.setItem('phoneNumber', tokenResponse.data.phoneNumber);
    localStorage.setItem('address', tokenResponse.data.address);
    localStorage.setItem('company', tokenResponse.data.company);
    localStorage.setItem('occupation', tokenResponse.data.occupation);
    localStorage.setItem('userId', tokenResponse.data.userId);
    localStorage.setItem('gateway', tokenResponse.data.isImGateway);
  
      localStorage.setItem('subscriptionType', tokenResponse.data.eStoreWebModels[0].subType);
      localStorage.setItem('subscriptionStart', tokenResponse.data.eStoreWebModels[0].subcriptionStartDate);
      localStorage.setItem('subscriptionEnd', tokenResponse.data.eStoreWebModels[0].subcriptionEndDate);
      localStorage.setItem('licenseCount', tokenResponse.data.eStoreWebModels[0].licenseCount);
      var result = Bowser.getParser(window.navigator.userAgent);
      //result.parsedResult.browser.version
    await UserSession('Add',tokenResponse.data.sessionId,'',tokenResponse.data.userId,await GetIpAddress(),document.referrer,'openathens',isMobile == true?'Mobile':'Desktop',browserName,result.parsedResult.browser.version,'')

    //setError({ email: false, password: false });
    history.replace('/Home'); // Redirect to the home page
    window.location.reload();
  } else {
    console.log('suc')
    setOpen(true);
  }
}


    }


    //console.log(tokenResponse);
  }, []);

  const history = useHistory();
  const handleSignUpClick = () => {
    if (loggedIn) {
      history.push('/home'); // Redirect to homepage if logged in
      window.location.reload();
    } else {
      history.push('/signup'); // Redirect to signup if not logged in
      window.location.reload();
    }
  };


  // Animation for fade in and slide up
  const { ref: refCaption, inView: inViewCaption } = useInView({
    triggerOnce: true, // Trigger animation once
    threshold: 0.1, // Trigger when 10% of the element is visible
  });
  const captionProps = useSpring({
     opacity: inViewCaption ? 1 : 0,
    transform: inViewCaption ? 'translateY(0)' : 'translateY(50px)',
    config: config.slow,
  });

  // Animation for smooth grow
  const { ref: refGrow, inView: inViewGrow } = useInView({
    triggerOnce: true, // Trigger animation once
    threshold: 0.3, // Trigger when 10% of the element is visible
  });
  const growProps = useSpring({
    opacity: inViewGrow ? 1 : 0,
    transform: inViewGrow ? 'scale(1)' : 'scale(0.9)',
    config: config.slow,
  });

  // Animation for fade in slide up in features section
  const { ref: refFeature, inView: inViewFeature } = useInView({
    triggerOnce: true, // Trigger animation once
    threshold: 0.1, // Trigger when 10% of the element is visible
  });
  const featureProps = useSpring({
    opacity: inViewFeature ? 1 : 0,
    transform: inViewFeature ? 'translateY(0)' : 'translateY(50px)',
    config: config.slow,
  });

  const { ref: refCards1, inView: inViewCards1 } = useInView({
    triggerOnce: true, // Trigger animation once
    threshold: 0.3, // Trigger when 10% of the element is visible
  });
  const cardsProps1 = useSpring({
    opacity: inViewCards1 ? 1 : 0,
    transform: inViewCards1 ? 'translateX(0)' : 'translateX(-50px)',
    config: config.slow,
  });

  const { ref: refCards2, inView: inViewCards2 } = useInView({
    triggerOnce: true, // Trigger animation once
    threshold: 0.3, // Trigger when 10% of the element is visible
  });
  const cardsProps2 = useSpring({
    opacity: inViewCards2 ? 1 : 0,
    transform: inViewCards2 ? 'translatex(0)' : 'translatex(50px)',
    config: config.slow,
  });

  const { ref: refVideos, inView: inViewVideos } = useInView({
    triggerOnce: true, // Trigger animation once
    threshold: 0.1, // Trigger when 10% of the element is visible
  });
  const videosProps = useSpring({
    opacity: inViewVideos ? 1 : 0,
    transform: inViewVideos ? 'translateY(0)' : 'translateY(50px)',
    config: config.slow,
  });

  const { ref: refCards3, inView: inViewCards3 } = useInView({
    triggerOnce: true, // Trigger animation once
    threshold: 0.3, // Trigger when 10% of the element is visible
  });
  const cardsProps3 = useSpring({
    opacity: inViewCards3 ? 1 : 0,
    transform: inViewCards3 ? 'translatex(0)' : 'translatex(-50px)',
    config: config.slow,
  });
  
  const cardData = [
    {
      title: "Dental-Specific Drug Information",
      description: "Concise information supporting oral health specialists enabling safer use of medicines",
      imageSrc: "/carousel/DentalSpecificDrugInformation.svg",
    },
    {
      title: "Towards Safer Prescribing",
      description: "Up-to-date relevant information on prescription, over-the-counter and complementary medicines",
      imageSrc: "/carousel/TowardsSaferPrescribing.svg",
    },
    {
      title: "Patient Education",
      description: "Patient-friendly information on appropriate use of antibiotics for specific dental scenarios",
      imageSrc: "/carousel/PatientEducation.svg",
    },
    {
        title: "Clinical Decision Support",
      description: "Information on medication-related effects and risks that can affect dental treatments",
      imageSrc: "/carousel/ClinicalDecisionSupport.svg",
    },
    {
      title: "Drug & Allergy Interactions",
      description: "Rapid access to primary evidence-based information on medicine interactions, contraindications with other medicines of interest or patient’s allergic profile.",
      imageSrc: "./videos/Drugs4dent-Interaction-Checker.mp4",
    },
    {
      title: "Pregnancy & Lactation Guide",
      description: "Recommendations on potentially unsafe medications during pregnancy and lactation for safer prescribing",
      imageSrc: "./videos/Drugs4dent-Pregnancy-Lactation-Guide.mp4",
    },
    {
      title: "Dosing Calculations",
      description: "Easy-to-use tool to assist with dosage recommendations especially paediatric dosing for dental indications",
      imageSrc: "./videos/Drugs4dent-Dosage-Calculator.mp4",
    },
  ];

  useEffect(() => {
    // scroll to #guides 
    if (window.location.hash === "#guides") {
        const guidesSection = document.getElementById("guides");
        if (guidesSection) {
            // Scroll with an offset for mobile devices
            window.scrollTo({
                top: guidesSection.offsetTop - 0, 
                behavior: "smooth",
            });
        }
    }
  }, []);

  const guideData = [
    {
      title: "User Guide",
      vidSrc: "./videos/guides/20241217_MIMS D4d_User Guide_revised_v9.pdf",
      imgSrc: "./videos/guides/UserGuide.jpg",
      description: "Downloadable and printable guide for new users"
    },{
      title: "How To Create An Account",
      vidSrc: "./videos/guides/CreateAccount.mp4",
      imgSrc: "./videos/guides/CreateAccount.jpg",
      description: "A step-by-step guide to creating an account and logging into the MIMS Drugs4dent."
    },
    {
      title: "Pregnancy & Lactation Guide",
      vidSrc: "./videos/guides/Pregnancy.mp4",
      imgSrc: "./videos/guides/Pregnancy.jpg",
      description: "Discover how to check medication safety and make informed decisions during pregnancy and lactation."
    },
    {
      title: "Dental Antibiotic Use Guide",
      vidSrc: "./videos/guides/Antibiotic.mp4",
      imgSrc: "./videos/guides/Antibiotic.jpg",
      description: "Understand how to choose suitable antibiotics for dental treatments and ensure safe prescriptions."
    },
    {
      title: "Drug & Allergy Interactions",
      vidSrc: "./videos/guides/interactions.mp4",
      imgSrc: "./videos/guides/interactions.jpg",
      description: "Learn how to use the Drug & Allergy Interactions tool."
    },
    {
      title: "Dosing Calculations",
      vidSrc: "./videos/guides/dosing.mp4",
      imgSrc: "./videos/guides/dosing.jpg",
      description: "Check to see how to calculate dosing recommendations."
    },
  ];

  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  const openDialog = (videoSrc) => {
    if(videoSrc.includes('.pdf')) {
        const newTab = window.open(videoSrc)
    }
    else {
      setCurrentVideo(videoSrc);
      setIsVideoOpen(true);
    }
  };

  const closeDialog = () => {
    setIsVideoOpen(false);
    setCurrentVideo(null);
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  
  };
  const [isLoading, setIsLoading] = useState(false); // State for loading
  
  return (
  <>
  <MetaTags>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
  </MetaTags>

  <Modal id="modal" open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box id="forgotPassword">
          <IconButton onClick={handleClose}  sx={{  position: 'absolute',  top: 16,  right: 16, color: 'grey.500',  }} >
            <IconX sx={{ fontSize: 32 }} />
          </IconButton>
      
            <> </>
        
            <>
              <img  src="/ForgotPassword.svg" alt="Illustration" style={{ maxWidth: '240px', width: '100%', height: 'auto', marginBottom: '16px' }}  />
              <Typography id="modal-modal-title" variant="h4" component="h2" gutterBottom sx={{ fontWeight: 'bold', color: '#0262AB' }}>
                Invalid OpenAthens
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2, mb: 4, color: '#727272', fontSize: { xs: '0.9rem', sm: '1rem', md: '1.2rem' } }}>
              Your OpenAthens account is not authorized to access MIMS Drugs4dent
              </Typography>
            </>
        </Box>
  </Modal> 

  <LandingPageBanner/>
  
  <Container fluid id="landingPage">
      <Row id="hero">
        <Col lg={6} md={12} className="d-flex flex-column justify-content-center " id="heroCaption">
          <animated.div ref={refCaption} style={captionProps}>
            <h1>Towards Safer Medication Use:</h1>
            <h2>A Tailored Medicines Resource For Dental Practitioners</h2>
            <h4>powered by <span>MIMS</span></h4>
            <Button id="signupButton" variant="outlined" color="primary" endIcon={loggedIn ? <IconHome /> : <IconArrowForward />} onClick={handleSignUpClick}>
              {loggedIn ? 'Go back to homepage' : 'Sign up for a 30-day trial'}
            </Button>
          </animated.div>
        </Col>
        <Col lg={6} md={12}  id="heroBanner">
        <Lottie animationData={animationData} loop={true} />
        </Col>
      </Row>
    
      <Row className="d-flex justify-content-center" id="discover">
        <Col className="text-center" id="videoContainer">
          <animated.div ref={refGrow} style={growProps}>
            <div className="react-player-container">
              <div className="aspect-ratio-container">
                <ReactPlayer
                  url='./videos/20240730_MIMSD4d_Video_V6_Final.mp4'
                  light='./videos/Drugs4dent-Teaser-Thumbnail.jpg'
                  controls={true}
                  playing
                  width='100%'
                  height='100%'
                  className="react-player"
                />
              </div>
            </div>
          </animated.div>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center" id="features">
        <animated.div ref={refFeature} style={featureProps}>
          <div className="sectionCaption">
            <h4>What's New?</h4>
            <h1>Succinct, Dental Relevant Information</h1>
          </div>
        </animated.div>
        <animated.div ref={refCards1} style={cardsProps1}>
        <Row className="row-equal-height">
          {cardData.slice(0, 4).map((card, index) => (
            <Col xs={10} md={6} lg={3} className="mb-4" key={index}>
              <Card className="card-custom">
                <div className="card-media">
                  <Card.Img variant="top" src={card.imageSrc} alt={card.title} />
                </div>
                <Card.Body>
                  <Card.Title className="card-title">{card.title}</Card.Title>
                  <Card.Text className="card-text">{card.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        </animated.div>
        <animated.div ref={refCards2} style={cardsProps2}>
        <Row className="row-equal-height">
          {cardData.slice(4).map((card, index) => (
            <Col xs={10} md={6} lg={4}  className="mb-4" key={index}>
              <Card className="card-custom">
                <div className="card-media-gif">
                  <video autoPlay playsinline muted loop controlsList="nodownload">
                    <source src={card.imageSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <Card.Body>
                  <Card.Title className="card-title">{card.title}</Card.Title>
                  <Card.Text className="card-text">{card.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        </animated.div>
      </Row>

      <Row className="d-flex justify-content-center" id="guides">
        <animated.div ref={refVideos} style={videosProps}>
          <div className="sectionCaption">
            <h4>Learn More</h4>
            <h1>How-to Guides and Videos</h1>
          </div>
        </animated.div>
        <animated.div ref={refCards3} style={cardsProps3}>
        <Row className="row-equal-height d-flex flex-row justify-content-md-center" id="videos">
          {guideData.map((vid, index) => (
            <Col xs={10} md={6} lg={4} className="mb-4" key={index}>
               <Card className={`card-custom ${currentVideo === vid.vidSrc ? "active" : ""}`}  onClick={() => openDialog(vid.vidSrc)}  style={{ cursor: "pointer" }} id="cardVideo">
                <CardContent className="card-body">
                    <Typography variant="h6">{vid.title} {vid.vidSrc.includes(".pdf") ? <sup><PictureAsPdfIcon /></sup> : ''}</Typography>
                    <Typography variant="body2" >
                      {vid.description}
                    </Typography>
                </CardContent>
                <div className="image-overlay-container">
                  <div className="play-icon">
                    {React.cloneElement(
                      currentVideo === vid.vidSrc ?  <PauseIcon /> : (vid.vidSrc.includes('.pdf') ? <DownloadForOfflineIcon /> : <PlayCircleRoundedIcon /> ),
                      { sx: { fontSize: '64px', color: "#0262AB" } }
                    )}
                    <div className="iconBg"></div>
                  </div>
                  <div className="media-container">
                    <CardMedia component="img" image={vid.imgSrc} alt={vid.title} className="card-thumbnail" />
                    <video src={vid.vidSrc} muted loop autoPlay playsInline style={{ width: "100%", height: "100%" }} className="video-preview" ></video>
                  </div>
                  <div className="color-overlay"></div>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
        </animated.div>
    
      </Row>

       {/*Lightbox */}
      <Dialog open={isVideoOpen} onClose={closeDialog} maxWidth="lg" fullWidth
        PaperProps={{ style: { backgroundColor: "black", color: "white", borderRadius: "32px" }, }}
      >
        <IconButton  onClick={closeDialog} style={{ position: "absolute", top: "10px", right: "10px", color: "white", zIndex: 1000, }} >
          <IconX />
        </IconButton>
        {currentVideo && (
          <video controls autoPlay style={{ width: "100%", height: "100%" }} >
            <source src={currentVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </Dialog>
  
  </Container>
  </>
  );
}

export default LandingPage;